import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { Component } from "@angular/core";
import { ThemeService } from "@app/shared/services/theme.service";
import { UpdateService } from "@app/shared/services/updateService";
import { Subscription } from "rxjs";
import {
  administratorIcon, airplaneIcon, angleIcon, arrowIcon, asteriskIcon, banIcon, bankIcon, barsIcon, boldIcon, bookIcon,
  buildingIcon, bulletListIcon, calculatorIcon, calendarIcon, certificateIcon, checkCircleIcon, checkIcon,
  childArrowIcon, circleArrowIcon, circleIcon, ClarityIcons, clipboardIcon, clockIcon, codeIcon, cogIcon, contractIcon,
  copyIcon, cursorMoveIcon, dashboardIcon, dollarBillIcon, downloadIcon, errorStandardIcon, exclamationCircleIcon,
  exclamationTriangleIcon, exportIcon, eyeHideIcon, eyeIcon, fileGroupIcon, fileIcon, filterGridIcon, filterIcon,
  filterOffIcon, flameIcon, floppyIcon, folderIcon, folderOpenIcon, fontSizeIcon, formIcon, helpInfoIcon, homeIcon,
  hourglassIcon, importIcon, infoCircleIcon, infoStandardIcon, italicIcon, keyboardIcon, lineChartIcon, linkIcon,
  listIcon, lockIcon, logoutIcon, mapMarkerIcon, minusIcon, noteIcon, organizationIcon, pencilIcon, pinIcon, unpinIcon, playIcon,
  plusCircleIcon, plusIcon, printerIcon, rackServerIcon, redoIcon, refreshIcon, repeatIcon, resizeIcon, routerIcon,
  searchIcon, shrinkIcon, sortByIcon, stopIcon, strikethroughIcon, successStandardIcon, switchIcon, syncIcon,
  timesCircleIcon, timesIcon, trashIcon, treeViewIcon, truckIcon, twoWayArrowsIcon, underlineIcon, undoIcon, unlockIcon,
  updateIcon, uploadIcon, userIcon, usersIcon, viewColumnsIcon, viewListIcon, warningStandardIcon, xlsFileIcon
} from "@cds/core/icon";
import { gavelIcon } from "@cds/core/icon/shapes/gavel";
import { pdfFileIcon } from "@cds/core/icon/shapes/pdf-file";

ClarityIcons.addIcons(
  pencilIcon,
  trashIcon,
  organizationIcon,
  refreshIcon,
  timesIcon,
  viewColumnsIcon,
  sortByIcon,
  filterIcon,
  plusIcon,
  syncIcon,
  angleIcon,
  floppyIcon,
  resizeIcon,
  shrinkIcon,
  copyIcon,
  noteIcon,
  hourglassIcon,
  circleIcon,
  circleArrowIcon,
  exclamationCircleIcon,
  exclamationTriangleIcon,
  clipboardIcon,
  downloadIcon,
  dashboardIcon,
  lineChartIcon,
  fileIcon,
  exportIcon,
  formIcon,
  warningStandardIcon,
  errorStandardIcon,
  gavelIcon,
  barsIcon,
  infoCircleIcon,
  searchIcon,
  asteriskIcon,
  usersIcon,
  userIcon,
  successStandardIcon,
  infoStandardIcon,
  eyeIcon,
	eyeHideIcon,
  uploadIcon,
  printerIcon,
  folderIcon,
  pinIcon,
  unpinIcon,
  filterGridIcon,
  italicIcon,
  boldIcon,
  underlineIcon,
  strikethroughIcon,
  fontSizeIcon,
  redoIcon,
  arrowIcon,
  bookIcon,
  lockIcon,
  unlockIcon,
  helpInfoIcon,
  listIcon,
  clockIcon,
  checkIcon,
  checkCircleIcon,
  fileGroupIcon,
  dollarBillIcon,
  certificateIcon,
  buildingIcon,
  truckIcon,
  flameIcon,
  contractIcon,
  undoIcon,
  updateIcon,
  filterOffIcon,
  childArrowIcon,
  bulletListIcon,
  rackServerIcon,
  truckIcon,
  treeViewIcon,
  cogIcon,
  plusCircleIcon,
  twoWayArrowsIcon,
  minusIcon,
  banIcon,
  bankIcon,
  folderOpenIcon,
  calculatorIcon,
  administratorIcon,
  keyboardIcon,
  homeIcon,
  routerIcon,
  buildingIcon,
  mapMarkerIcon,
  codeIcon,
  importIcon,
  cursorMoveIcon,
  airplaneIcon,
  flameIcon,
  timesCircleIcon,
  xlsFileIcon,
  pdfFileIcon,
	linkIcon,
  viewListIcon,
  switchIcon,
  playIcon,
  stopIcon,
  calendarIcon,
  logoutIcon,
  repeatIcon,
  redoIcon
);

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["../styles-app-loading.scss"]
})
export class AppComponent {
  public show = true;
  private navigateSubscription: Subscription;

  sub: Subscription = new Subscription();

  constructor(
    private router: Router,
    private themeService: ThemeService,
    public updateService: UpdateService
  ) {

    themeService.setStartTheme();
    this.sub.add(themeService.themeChangedEmitted.subscribe((theme) => {
      themeService.setTheme(theme);
    }));

    this.sub.add(this.navigateSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.show = true;
      } else if (event instanceof NavigationEnd) {
        this.show = false;
        this.navigateSubscription.unsubscribe();
      }
    }));
  }

  update = () => this.updateService.update();
  close = () => this.updateService.close();

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    caches.keys().then((keys) => keys.forEach((key) => caches.delete(key)));
  }
}
